import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { getSubUsersListThunk, openModal } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import BreadCrumb from "Common/BreadCrumb";
import Datatable from "Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
// import { getInitials } from "helpers/common";
import Tooltip from "rc-tooltip";

const Users = () => {
  const dispatch = useDispatch<any>();

  const selectAccount = createSelector(
    (state: any) => state.Account,
    (account) => ({
      loading4: account.loading2,
      subUsersData: account.subUsersData,
      error: account.error,
    })
  );

  const { loading4, subUsersData } = useSelector(selectAccount);

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Name</span>,
      selector: (row: { firstName: string; lastName: string }) =>
        `${row?.firstName || ""} ${row?.lastName || ""}`,
      cell: (row: { firstName: string; lastName: string }) => (
        <div className="d-flex align-items-center">
          <img
            className="rounded-circle header-profile-user"
            src={"https://cdn-icons-png.flaticon.com/512/219/219988.png"}
            alt="Avatar"
          />
          <span className="text-start ms-2 d-inline-block user-name-text">
            {row?.firstName} {row?.lastName}
          </span>
        </div>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        `${a.firstName || ""} ${a.lastName || ""}`.localeCompare(
          `${b.firstName || ""} ${b.lastName || ""}`
        ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Email ID</span>,
      selector: (row: { emailId: string }) => row.emailId || "",
      cell: (row: { emailId: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-envelope me-2 fs-xl text-secondary" />
          {row.emailId || ""}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.emailId || "").localeCompare(b.emailId || ""),
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (row: { firstName: string; emailId: string }) => {
        return (
          <div className="d-flex align-items-center">
            <Tooltip
              placement="bottom"
              overlay={<p className="mb-0">Login Details</p>}
            >
              <i
                className="ri-eye-fill align-middle me-3 text-muted fs-xl"
                onClick={() => {
                  dispatch(
                    openModal({
                      path: "SubUserDetails",
                      data: {
                        title: `${row.firstName}'s Details`,
                        footer: true,
                        cancelBtn: true,
                        userEmailId: row.emailId,
                        getSubUsersData: () =>
                          getSubUsersData(currPage, rowsPerPage),
                      },
                    })
                  );
                }}
              />
            </Tooltip>
            <Tooltip
              placement="bottom"
              overlay={<p className="mb-0">Change Password</p>}
            >
              <i
                className="ri-lock-2-fill align-middle text-muted fs-xl"
                onClick={() => {
                  dispatch(
                    openModal({
                      path: "ChangeSubUserPassword",
                      data: {
                        title: `Change Password for ${row.firstName}`,
                        footer: false,
                        userEmailId: row.emailId,
                      },
                    })
                  );
                }}
              />
            </Tooltip>
          </div>
        );
      },
      sortable: false,
    },
  ];

  useEffect(() => {
    getSubUsersData(currPage, rowsPerPage);
  }, []);

  const getSubUsersData = (
    page: number,
    perPage: number,
    otherParams?: any
  ) => {
    dispatch(getSubUsersListThunk(page, perPage, otherParams || {}));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    getSubUsersData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    getSubUsersData(1, rows);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Users"
            isCreate={true}
            createLabel="Add User"
            createClick={() => {
              dispatch(
                openModal({
                  path: "AddSubUser",
                  data: {
                    title: "Add User",
                    footer: false,
                    getSubUsersData: () =>
                      getSubUsersData(currPage, rowsPerPage),
                  },
                })
              );
            }}
          />
          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading4 ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(subUsersData?.records)
                        ? subUsersData?.records
                        : []
                    }
                    columns={columns}
                    className="subUserTable"
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={subUsersData?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
