// @ts-nocheck
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import getChartColorsArray from "Common/ChartsDynamicColor";
import Loader from "assets/images/spinner-dark.svg";
import { capitalizeString, removeKeyFromObj } from "helpers/common";
import * as d3 from "d3";

const SummaryChart = ({ data, labels }) => {
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);
  const [currentState, setCurrentState] = useState("total");
  const [showPercentage, setShowPercentage] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const togger = useRef(false);
  useEffect(() => {
    if (!svgRef.current) return;

    const total = data.reduce((a, b) => a + b, 0);
    const [deliveryCount, failedCount] = data;

    // Calculate percentages, defaulting to 1 if total is 0
    const percentages =
      total === 0 ? data.map(() => 1) : data.map((value) => value / total);

    // Set up dimensions
    const width = 150;
    const height = 150;
    const radius = Math.min(width, height) / 2;
    const ringWidth = 9;
    const ringGap = 7;

    const svg = d3.select(svgRef.current);
    const tooltip = d3.select(tooltipRef.current);

    // Clear previous content
    svg.selectAll("*").remove();

    // Create chart group
    const g = svg
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    // Set up pie generator
    const pie = d3.pie().sort(null);

    // Set up arc generators with increased space between circles
    const arcOuter = d3
      .arc()
      .innerRadius(radius - ringWidth)
      .outerRadius(radius);

    const arcInner = d3
      .arc()
      .innerRadius(radius - ringWidth - ringGap - ringWidth)
      .outerRadius(radius - ringWidth - ringGap);

    // Color scales
    const color = d3
      .scaleOrdinal()
      .domain([0, 1])
      .range(["#6868ab", "#E5E5E5"]);
    const color2 = d3
      .scaleOrdinal()
      .domain([0, 1])
      .range(["#e41cfd", "#E5E5E5"]);

    // Function to update center text
    const updateCenterText = (state, showPercentage = false) => {
      if (state === "delivery") {
        labelTspan.text(labels[0]);
        valueTspan.text(
          showPercentage
            ? getPercentage(deliveryCount)
            : deliveryCount.toFixed(0)
        );
      } else if (state === "failed") {
        labelTspan.text(labels[1]);
        valueTspan.text(
          showPercentage ? getPercentage(failedCount) : failedCount.toFixed(0)
        );
      } else {
        resetCenterText();
      }
    };

    const resetCenterText = () => {
      labelTspan.text("Total");
      labelTspanv.text("Total").style("display", "none");
      labelTspan1.text("Total").style("display", "none");
      labelTspanv2.text("Total").style("display", "none");
      valueTspan.text(total.toFixed(0));
      togger.current = false;
    };

    // Function to calculate and format percentage
    const getPercentage = (count) => {
      if (total === 0) return "0%";
      return `${((count / total) * 100).toFixed(1)}%`;
    };

    // Function to update chart state
    const updateChartState = (state) => {
      setCurrentState(state);
      setShowPercentage(false);
      updateRingHighlight(state);
      updateCenterText(state);
    };

    // Function to update ring highlight
    const updateRingHighlight = (state) => {
      svg.selectAll(".arc").style("opacity", 1);
      d3.selectAll(".legend-item").classed("active", false);

      if (state === "delivery") {
        svg.selectAll(".innerArc").style("opacity", 0.3);
        d3.select(".legend-item:nth-child(1)").classed("active", true);
      } else if (state === "failed") {
        svg.selectAll(".outerArc").style("opacity", 0.3);
        d3.select(".legend-item:nth-child(2)").classed("active", true);
      }
    };

    // Function to show tooltip
    const showTooltip = (event, d, isOuter) => {
      const [x, y] = d3.pointer(event, svg.node());
      const count = isOuter ? deliveryCount : failedCount;
      const text =
        total === 0
          ? `${isOuter ? labels[0] : labels[1]}: 0 (0%)`
          : `${isOuter ? labels[0] : labels[1]}: ${count} (${getPercentage(
              count
            )})`;
      tooltip
        .style("opacity", 1)
        .html(text)
        .style("left", `${x + 10}px`)
        .style("top", `${y - 10}px`);
    };

    // Function to hide tooltip
    const hideTooltip = () => {
      tooltip.style("opacity", 0);
    };

    // Draw outer circle
    g.selectAll(".outerArc")
      .data(pie(total === 0 ? [1] : [percentages[0], 1 - percentages[0]]))
      .enter()
      .append("path")
      .attr("class", "arc outerArc")
      .attr("d", arcOuter)
      .attr("fill", (d, i) => (total === 0 ? color(1) : color(i)))
      .on("click", (event) => {
        event.stopPropagation();
        updateChartState("delivery");
      })
      .on("mousemove", (event, d) => showTooltip(event, d, true))
      .on("mouseout", hideTooltip);

    // Draw inner circle only if there are multiple values
    if (data.length > 1) {
      g.selectAll(".innerArc")
        .data(pie(total === 0 ? [1] : [percentages[1], 1 - percentages[1]]))
        .enter()
        .append("path")
        .attr("class", "arc innerArc")
        .attr("d", arcInner)
        .attr("fill", (d, i) => (total === 0 ? color2(1) : color2(i)))
        .on("click", (event) => {
          event.stopPropagation();
          updateChartState("failed");
        })
        .on("mousemove", (event, d) => showTooltip(event, d, false))
        .on("mouseout", hideTooltip);
    }

    // Add center text
    const centerText = g
      .append("text")
      .attr("class", "center-text")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "central")
      .attr("y", -20)
      .style("font-size", "14px")
      .style("cursor", "pointer");

    // Add label tspan
    const labelTspan = centerText
      .append("tspan")
      .attr("x", 0)
      .attr("class", "label-text")
      .text("Total");
    const labelTspanv = centerText
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .attr("class", "label-text")
      .text("Total")
      .style("font-weight", "bold")
      .style("display", "none")
      .style("font-size", "14px");
    const labelTspan1 = centerText
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .attr("class", "label-text")
      .text("Total")
      .style("display", "none");
    const labelTspanv2 = centerText
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .attr("class", "label-text")
      .text("Total")
      .style("display", "none")
      .style("font-weight", "bold")
      .style("font-size", "14px");
    // Add value tspan
    const valueTspan = centerText
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .text(`${total.toFixed(0)}`)
      .style("font-size", "18px")
      .style("font-weight", "bold");

    // Add click event listener to reset when clicking outside the chart
    d3.select("body").on("click", () => {
      updateChartState("total");
    });

    // Update center text event handlers
    centerText.on("click", (event) => {
      event.stopPropagation();
      if (currentState === "total") {
        togger.current = !togger.current;
        // Calculate and show percentages for all rings
        const deliveryPercentage = getPercentage(deliveryCount);
        const failedPercentage = getPercentage(failedCount);
        if (togger.current) {
          if (labels[0]) {
            labelTspan.text(labels[0]);
            labelTspanv.text(deliveryPercentage).style("display", "block");
          }
          if (labels[1]) {
            labelTspan1.text(labels[1]).style("display", "block");
            labelTspanv2.text(failedPercentage).style("display", "block");
          }
          valueTspan.text("");
        } else {
          resetCenterText();
        }
      } else {
        const isShowingPercentage = !showPercentage;
        setShowPercentage(isShowingPercentage);
        updateCenterText(currentState, isShowingPercentage);
      }
    });

    // Remove existing legend before adding a new one
    d3.select(svgRef.current.parentNode).select(".legend").remove();

    // Add legend
    const legend = d3
      .select(svgRef.current.parentNode)
      .append("div")
      .attr("class", "legend");

    const legendItems = [
      { label: labels[0], color: color(0), state: "delivery" },
      ...(data.length > 1
        ? [{ label: labels[1], color: color2(0), state: "failed" }]
        : []),
    ];

    legend
      .selectAll(".legend-item")
      .data(legendItems)
      .enter()
      .append("div")
      .attr("class", "legend-item")
      .html(
        (d) => `
        <div class="legend-color" style="background-color: ${d.color};"></div>
        <div>${d.label}</div>
      `
      )
      .on("click", (event, d) => {
        event.stopPropagation();
        updateChartState(d.state);
      });

    // Update center text and ring highlight based on current state and showPercentage
    updateRingHighlight(currentState);
    if (currentState !== "total") {
      const count = currentState === "delivery" ? deliveryCount : failedCount;
      updateCenterText(currentState, showPercentage);
    }
  }, [currentState, showPercentage, data, labels, showPopover]);

  return (
    <div className="d-flex align-items-center flex-column position-relative">
      <svg ref={svgRef}></svg>
      <div ref={tooltipRef} className="tooltip"></div>
      <style jsx>{`
        .tooltip {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
          padding: 8px 12px;
          border-radius: 5px;
          font-size: 12px;
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.2s ease;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          transform: translate(-50%, -50%);
          z-index: 1000;
        }
        .percentage-list {
          display: flex;
          flex-direction: column;
          gap: 6px;
        }
        .percentage-item {
          display: flex;
          align-items: center;
          gap: 8px;
          white-space: nowrap;
        }
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          display: inline-block;
        }
        .legend {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
        .legend-item {
          display: flex;
          align-items: center;
          margin: 0 10px;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 20px;
          transition: all 0.3s ease;
        }
        .legend-item:hover,
        .legend-item.active {
          background-color: rgba(224, 224, 224, 0.8);
        }
        .legend-color {
          width: 12px;
          height: 12px;
          margin-right: 8px;
          border-radius: 50%;
        }
      `}</style>
    </div>
  );
};

const UsageSummary = ({ cardObj }: any) => {
  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.UsageSummary,
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (usageSummary, analytics, groups) => ({
      loading: analytics.loading,
      usageSummaryObj: usageSummary.usageSummaryObj,
      error: usageSummary.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, usageSummaryObj } = useSelector(selectProfile);

  const [summaryObj, setSummaryObj] = useState<any>({});

  const orderedKeys = ["Total", "SMS", "MMS", "Total Opt-Out"];

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      const reorderedObj: any = {};
      orderedKeys.forEach((key) => {
        if (temp[key]) {
          reorderedObj[key] = temp[key];
        }
      });
      setSummaryObj(reorderedObj);
    }
  }, [usageSummaryObj]);

  return (
    <Col xl={9} md={12} sm={6} className="mb-4">
      <Card className="card card-height-100 overflow-hidden mb-0 h-100">
        <Card.Header className="align-items-center d-flex pb-3">
          <h4 className="card-title mb-0 flex-grow-1">Messaging Insights</h4>
        </Card.Header>
        {loading ? (
          <div style={{ height: 60 }}>
            <img
              src={Loader}
              className={`position-absolute top-50 start-50 translate-middle`}
            />
          </div>
        ) : (
          <Card.Body className="p-0 dashsummary">
            <Row className="g-0">
              <Col md={4}>
                <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                  <Card.Header className="pt-2">
                    <h6 className="mb-0">Messaging at a Glance</h6>
                  </Card.Header>
                  <Card.Body className="p-2 pt-4">
                    <div id="summary-total123" dir="ltr">
                      <SummaryChart
                        data={[
                          cardObj?.All?.delivered || 0,
                          cardObj?.All?.failed || 0,
                        ]}
                        key="sms"
                        // colors='["--tb-secondary", "--tb-primary"]'
                        labels={["Delivered", "Failed"]}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                  <Card.Header className="pt-2">
                    <h6 className="mb-0">SMS at a Glance</h6>
                  </Card.Header>
                  <Card.Body className="p-2 pt-4">
                    <div id="summary-total-1" dir="ltr">
                      <SummaryChart
                        key="smsd"
                        data={[
                          cardObj?.SMS?.delivered || 0,
                          cardObj?.SMS?.failed || 0,
                        ]}
                        dataColors='["--tb-secondary", "--tb-primary"]'
                        labels={["Delivered", "Failed"]}
                        trackBg={["#6868ab", "#e41cfd"]}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                  <Card.Header className="pt-2">
                    <h6 className="mb-0">MMS at a Glance</h6>
                  </Card.Header>
                  <Card.Body className="p-2 pt-4">
                    <div id="summary-total-3" dir="ltr">
                      <SummaryChart
                        key="mms"
                        data={[
                          cardObj?.MMS?.delivered || 0,
                          cardObj?.MMS?.failed || 0,
                        ]}
                        dataColors='["--tb-secondary", "--tb-primary"]'
                        labels={["Delivered", "Failed"]}
                        trackBg={["#6868ab", "#e41cfd"]}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {Object.keys(summaryObj)?.length > 0 &&
                Object.keys(summaryObj).map(
                  (dt1, i1) =>
                    typeof summaryObj?.[dt1] === "object" &&
                    !Array.isArray(summaryObj?.[dt1]) &&
                    summaryObj?.[dt1] !== null && (
                      <Col md={4} key={dt1}>
                        <Card className={`shadow-none rounded-0 mb-0 border`}>
                          <Card.Header className="pt-2">
                            <h6 className="mb-0">Total {dt1}</h6>
                          </Card.Header>
                          <Card.Body className="p-2 pt-4">
                            <div id={`summary-[${dt1}]`} dir="ltr">
                              <SummaryChart
                                key={`mmsd_${i1}`}
                                data={
                                  Object.keys(summaryObj?.[dt1])?.length > 0
                                    ? Object.keys(summaryObj?.[dt1]).map(
                                        (dt2) =>
                                          usageSummaryObj?.total?.[dt1]?.[dt2]
                                            ?.transactions || 0
                                      )
                                    : [0]
                                }
                                labels={
                                  Object.keys(summaryObj?.[dt1])?.length > 0
                                    ? Object.keys(summaryObj?.[dt1]).map(
                                        (dt2) => capitalizeString(dt2)
                                      )
                                    : ["No Data"]
                                }
                                trackBg={["#6868ab", "#e41cfd"]}
                                dataColors='["--tb-secondary", "--tb-primary"]'
                              />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                )}

              <Col md={4}>
                <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                  <Card.Header className="pt-2">
                    <h6 className="mb-0">Opt-Outs</h6>
                  </Card.Header>
                  <Card.Body className="p-2 pt-4">
                    <div id="summary-total" dir="ltr">
                      <SummaryChart
                        key="opt-out"
                        data={[cardObj?.All?.optOutRate || 0]}
                        dataColors='["--tb-secondary"]'
                        labels={["Opt-Out"]}
                        trackBg={["#6868ab"]}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </Col>
  );
};

export default UsageSummary;
