import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
} from "react-bootstrap";
import Radiobox from "Common/Radiobox";
import Dropzone from "react-dropzone";
import TagsInput from "react-tagsinput";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "assets/images/spinner-white.svg";

const Step3 = (props: any) => {
  const {
    validation3,
    validation1,
    handleDeleteMsg,
    imgLoading,
    handleDeleteImg,
    handleDownloadImg,
    handleViewImg,
    handleAcceptedFiles,
    selectedFiles,
    // resellersArr,
    campaignTnC,
    setactiveTab,
    // setResellerModal,
    isLoading,
    campaignTerms,
  } = props;

  // const [acceptedFiles, setAcceptedFiles] = useState([]);
  // const [rejectedFiles, setRejectedFiles] = useState([]);
  const [campConditions, setCampConditions] = useState<any>({});

  useEffect(() => {
    const temp2 =
      campaignTerms?.mnoMetadata &&
      Object.fromEntries(
        Object.entries(campaignTerms?.mnoMetadata).filter(
          ([key, value]: any) => value?.tpmScope === "CAMPAIGN"
        )
      );
    setCampConditions((temp2 && Object.values(temp2)[0]) || {});
  }, [campaignTerms]);

  useEffect(() => {
    if (Object.keys(campConditions)?.length > 0) {
      validation3.setFieldValue(
        "messages",
        new Array(campConditions?.minMsgSamples).fill("")
      );
    }
  }, [campConditions]);

  return (
    <Tab.Pane
      eventKey="2"
      id="pills-success"
      role="tabpanel"
      aria-labelledby="pills-success-tab"
    >
      <Form
        action="#"
        onSubmit={(e) => {
          e.preventDefault();
          validation3.handleSubmit();
        }}
      >
        <div>
          <div className="mb-4">
            <div>
              <h5>Brand</h5>
            </div>
          </div>
          <Row>
            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="brandName2">Brand Name</Form.Label>
              <Form.Control
                type="text"
                name="brandName2"
                value={validation1.values.brandId}
                disabled={true}
              />
            </Col>
          </Row>

          <div className="mt-5 mb-4">
            <h5>Campaign Details</h5>
          </div>
          <Row>
            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="usecase">Use-Case</Form.Label>
              <Form.Control
                type="text"
                name="usecase"
                value={validation1.values.usecase}
                disabled={true}
              />
            </Col>
            <Col lg={6} />
            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="privacyPolicyLink">
                Privacy Policy Link
              </Form.Label>
              <Form.Control
                type="url"
                name="privacyPolicyLink"
                onChange={validation3.handleChange}
                onBlur={validation3.handleBlur}
                value={validation3.values.privacyPolicyLink}
                isInvalid={
                  validation3?.touched?.privacyPolicyLink &&
                  validation3?.errors?.privacyPolicyLink
                    ? true
                    : false
                }
              />
              {validation3.touched.privacyPolicyLink &&
              validation3.errors.privacyPolicyLink ? (
                <Form.Control.Feedback type="invalid">
                  {validation3?.errors?.privacyPolicyLink}
                </Form.Control.Feedback>
              ) : null}
            </Col>
            <Col lg={6} className="mb-3">
              <Form.Label htmlFor="termsAndConditionsLink">
                Terms and Conditions Link
              </Form.Label>
              <Form.Control
                type="url"
                name="termsAndConditionsLink"
                onChange={validation3.handleChange}
                onBlur={validation3.handleBlur}
                value={validation3.values.termsAndConditionsLink}
                isInvalid={
                  validation3?.touched?.termsAndConditionsLink &&
                  validation3?.errors?.termsAndConditionsLink
                    ? true
                    : false
                }
              />
              {validation3.touched.termsAndConditionsLink &&
              validation3.errors.termsAndConditionsLink ? (
                <Form.Control.Feedback type="invalid">
                  {validation3?.errors?.termsAndConditionsLink}
                </Form.Control.Feedback>
              ) : null}
            </Col>
            <Col lg={12} className="mb-3">
              <Form.Label htmlFor="description">
                Campaign Description <span className="text-primary">*</span>
              </Form.Label>
              <textarea
                id="description"
                name="description"
                onChange={validation3.handleChange}
                onBlur={validation3.handleBlur}
                value={validation3.values.description || ""}
                className={`form-control ${
                  validation3.touched?.description &&
                  validation3.errors?.description
                    ? "is-invalid"
                    : ""
                }`}
                rows={3}
                maxLength={4096}
                minLength={40}
              />
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {validation3.touched?.description &&
                  validation3.errors?.description ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {validation3.errors?.description}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
                <div className="">
                  {validation3.values.description?.length} / 4096
                </div>
              </div>
            </Col>
            <Col lg={12} className="mb-3">
              <Form.Label htmlFor="messageFlow">
                Call-to-Action / Message Flow{" "}
                <span className="text-primary">*</span>
              </Form.Label>
              <textarea
                id="messageFlow"
                name="messageFlow"
                onChange={validation3.handleChange}
                onBlur={validation3.handleBlur}
                value={validation3.values.messageFlow || ""}
                className={`form-control ${
                  validation3.touched?.messageFlow &&
                  validation3.errors?.messageFlow
                    ? "is-invalid"
                    : ""
                }`}
                rows={3}
                maxLength={4096}
                minLength={40}
              />
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {validation3.touched?.messageFlow &&
                  validation3.errors?.messageFlow ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {validation3.errors?.messageFlow}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
                <div className="">
                  {validation3.values.messageFlow?.length} / 4096
                </div>
              </div>
            </Col>
          </Row>

          <div className="mt-5 mb-4">
            <h5>Sample Messages</h5>
          </div>
          <Row>
            {validation3?.values.messages?.length > 0
              ? validation3?.values.messages?.map((msg: string, i: number) => (
                  <Col lg={6} key={i} className="mb-3">
                    <Form.Label htmlFor={`messages[${i}]`}>
                      Sample message {i + 1}{" "}
                      <span className="text-primary">*</span>{" "}
                      {i + 1 > campConditions?.minMsgSamples ? (
                        <span onClick={() => handleDeleteMsg(i)}>
                          <i className="ri-delete-bin-line fs-lg text-danger cursor-pointer"></i>
                        </span>
                      ) : null}
                    </Form.Label>
                    <textarea
                      id={`messages[${i}]`}
                      name={`messages[${i}]`}
                      onChange={validation3.handleChange}
                      onBlur={validation3.handleBlur}
                      value={validation3.values.messages?.[i] || ""}
                      className={`form-control ${
                        validation3.touched?.messages?.[i] &&
                        validation3.errors?.messages?.[i]
                          ? "is-invalid"
                          : ""
                      }`}
                      rows={3}
                      maxLength={1024}
                      minLength={20}
                    />
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        {validation3.touched?.messages?.[i] &&
                        validation3.errors?.messages?.[i] ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors?.messages?.[i]}
                          </Form.Control.Feedback>
                        ) : null}
                      </div>
                      <div className="">
                        {validation3.values.messages?.[i]?.length} / 1024
                      </div>
                    </div>
                  </Col>
                ))
              : null}
          </Row>
          {validation3?.values.messages?.length < 5 ? (
            <Row>
              <Col lg={12}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    validation3.setFieldValue("messages", [
                      ...validation3.values.messages,
                      "",
                    ]);
                  }}
                  variant="primary"
                  className="d-flex m-auto"
                >
                  Add Sample Message
                </Button>
              </Col>
            </Row>
          ) : null}

          <div className="mt-5 mb-4">
            <h5>Sample Multimedia</h5>
          </div>
          <Row className="mb-3">
            <Col lg={6}>
              <Dropzone
                onDrop={(acceptedFiles: any, fileRejections: any) => {
                  if (selectedFiles?.length > 4) {
                    toast.error("Max 5 uploads per campaign is allowed");
                  } else {
                    fileRejections?.length > 0 &&
                      console.log("fileRejections", fileRejections);
                    fileRejections?.forEach((file: any) => {
                      file.errors.forEach(
                        (err: { code: string; message: string }) => {
                          if (err.code === "file-too-large") {
                            toast.error("File is larger than 10MB");
                          }

                          if (err.code === "file-invalid-type") {
                            toast.error(err.message || "Something went wrong");
                          }
                        }
                      );
                    });
                    handleAcceptedFiles(acceptedFiles, fileRejections);
                    // setAcceptedFiles(acceptedFiles);
                    // setRejectedFiles(fileRejections);
                  }
                }}
                maxSize={10485760} // 10mb
                multiple={false}
                // accept={{
                //   "image/*": [],
                //   "video/*": [],
                //   "audio/*": [],
                // }}
                accept={`.3ga, .3gp, .amr, .avi, .bmp, .bz2, .cal, .css, .csv, .dib, .flac,
                  .flv, .gif, .gz, .ico, .jpeg, .jpg, .js,  .m1a, .m1v, .m2a,
                  .m4a, .m4b, .m4p, .m4r, .m4v, .mov, .mp1, .mp2, .mp3, .mp4, .mpa,
                  .mpeg, .mpg, .mpv, .oga, .ogg, .ogm, .ogv, .ogx, .pdf, .pjpeg, .png,
                  .qt, .rtf, .smil, .spx, .svg, .tar, .tif, .tiff, .txt, .vcard, .vcf,
                  .wap, .wav, .webm, .webp, .wmv, .xml, .zip`}
              >
                {({ getRootProps }: any) => (
                  <div className="position-relative dropzone dz-clickable text-center border rounded">
                    {isLoading ? (
                      <div className="d-flex justify-content-center align-items-center position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-50">
                        <img
                          src={Loader}
                          alt="loading"
                          className="img-thumbnail border-0 bg-transparent"
                        />
                      </div>
                    ) : null}
                    <div
                      className="dz-message needsclick cursor-pointer"
                      {...getRootProps()}
                    >
                      <div className="mb-3">
                        <i className="ri-camera-fill text-muted fs-8xl" />
                        <i className="ri-video-fill text-muted fs-8xl" />
                        <i className="ri-file-music-fill text-muted fs-8xl" />
                      </div>
                      <p className="fs-lg">
                        Drop a multimedia file to upload or click to upload
                      </p>
                      <p className="fs-md text-muted">
                        Maximum upload file size: 10MB
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Col>
            <Col lg={6}>
              <div className="border rounded p-3 dropzone flex-column">
                <h6 className="mb-3">Sample Multimedia Files</h6>
                <ul className="vstack gap-2 list-unstyled mb-0">
                  {selectedFiles?.map(
                    (dt: { type: string; name?: string }, i: number) => (
                      <li key={i}>
                        <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                          <div className="d-flex align-items-center gap-2">
                            <div className="avatar-xs flex-shrink-0 ronded">
                              <div className="avatar-title bg-body-secondary text-body">
                                {dt?.type.startsWith("image/") ? (
                                  <i className="ri ri-image-line fs-lg"></i>
                                ) : dt?.type.startsWith("video/") ? (
                                  <i className="ri ri-video-line fs-lg"></i>
                                ) : (
                                  <i className="ri ri-file-line fs-lg"></i>
                                )}
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-0">{dt?.name || ""}</h6>
                            </div>
                            <div className="flex-shrink-0 text-muted">
                              <i
                                className="ri-download-2-fill cursor-pointer fs-2xl text-secondary ms-3"
                                onClick={() => {
                                  !imgLoading && handleDownloadImg(i);
                                }}
                              />
                              <i
                                className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
                                onClick={() => {
                                  !imgLoading && handleViewImg(i);
                                }}
                              />
                              <i
                                className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                                onClick={() => {
                                  !imgLoading && handleDeleteImg(i);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12}>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleAcceptedFiles(acceptedFiles, rejectedFiles);
                  setAcceptedFiles([]);
                  setRejectedFiles([]);
                }}
                variant="primary"
                className="d-flex m-auto"
                disabled={acceptedFiles?.length < 1 || isLoading}
              >
                Add Sample Multimedia
              </Button>
            </Col>
          </Row> */}

          <div className="mt-5 mb-4">
            <h5>Campaign and Content Attributes</h5>
            <p className="text-muted">
              (For M2M (Machine-to-Machine) Use Case, please select No if the
              attributes are not applicable.)
            </p>
          </div>
          <Row>
            <Col lg={6}>
              <ul className="list-group list-group-flush">
                <li className="list-group-item ps-0 pb-1">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 overflow-hidden">
                      <label className="form-check-label mb-0">
                        <span className="d-block fw-semibold text-truncate">
                          Subscriber Opt-in
                        </span>
                        {validation3.touched.subscriberOptin &&
                        validation3.errors.subscriberOptin ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.subscriberOptin}
                          </Form.Control.Feedback>
                        ) : null}
                        {validation3.touched.optinKeywords &&
                        validation3.errors.optinKeywords ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.optinKeywords}
                          </Form.Control.Feedback>
                        ) : null}
                        {validation3.touched.optinMessage &&
                        validation3.errors.optinMessage ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.optinMessage}
                          </Form.Control.Feedback>
                        ) : null}
                      </label>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-4">
                          <Radiobox
                            id="subscriberOptinYes"
                            label="Yes"
                            name="subscriberOptin"
                            value="Yes"
                            checked={
                              validation3.values.subscriberOptin === true
                            }
                            onChange={() =>
                              validation3.setFieldValue("subscriberOptin", true)
                            }
                          />
                        </div>
                        <div className="me-5">
                          <Radiobox
                            id="subscriberOptinNo"
                            label="No"
                            name="subscriberOptin"
                            value="No"
                            disabled={campConditions?.reqSubscriberOptin}
                            checked={
                              validation3.values.subscriberOptin === false
                            }
                            onChange={() =>
                              validation3.setFieldValue(
                                "subscriberOptin",
                                false
                              )
                            }
                          />
                        </div>
                        <div
                          className="d-flex align-items-start mb-2"
                          style={{ width: 16, height: 24 }}
                        >
                          {validation3.values.subscriberOptin ? (
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="bottom-end"
                              overlay={
                                <Popover id="popover-positioned-bottom">
                                  <Popover.Body>
                                    <div className="mb-3">
                                      <Form.Label htmlFor="optinKeywords">
                                        Type Opt-in Keywords here{" "}
                                        <span className="text-primary">*</span>
                                      </Form.Label>
                                      <TagsInput
                                        inputProps={{
                                          placeholder: "Add a keywords",
                                        }}
                                        value={
                                          validation3.values.optinKeywords
                                            ? validation3.values.optinKeywords.split(
                                                ","
                                              )
                                            : []
                                        }
                                        onChange={(e: any) => {
                                          validation3.setFieldValue(
                                            "optinKeywords",
                                            e.toString()
                                          );
                                        }}
                                        addOnBlur={true}
                                        onlyUnique={true}
                                      />
                                      <div>
                                        {validation3.touched?.optinKeywords &&
                                        validation3.errors?.optinKeywords ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {validation3.errors?.optinKeywords}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="mb-3">
                                      <Form.Label htmlFor="optinMessage">
                                        Type Opt-in Message here{" "}
                                        <span className="text-primary">*</span>
                                      </Form.Label>
                                      <textarea
                                        id="optinMessage"
                                        name="optinMessage"
                                        onChange={validation3.handleChange}
                                        onBlur={validation3.handleBlur}
                                        value={
                                          validation3.values.optinMessage || ""
                                        }
                                        className={`form-control ${
                                          validation3.touched?.optinMessage &&
                                          validation3.errors?.optinMessage
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        rows={3}
                                        maxLength={4096}
                                        minLength={40}
                                      />
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          {validation3.touched?.optinMessage &&
                                          validation3.errors?.optinMessage ? (
                                            <Form.Control.Feedback
                                              type="invalid"
                                              className="d-block"
                                            >
                                              {validation3.errors?.optinMessage}
                                            </Form.Control.Feedback>
                                          ) : null}
                                        </div>
                                        <div style={{ flex: "none" }}>
                                          {
                                            validation3.values.optinMessage
                                              ?.length
                                          }{" "}
                                          / 320
                                        </div>
                                      </div>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
                            </OverlayTrigger>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ps-0 pb-1">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 overflow-hidden">
                      <label className="form-check-label mb-0">
                        <span className="d-block fw-semibold text-truncate">
                          Subscriber Opt-Out
                        </span>
                        {validation3.touched.subscriberOptout &&
                        validation3.errors.subscriberOptout ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.subscriberOptout}
                          </Form.Control.Feedback>
                        ) : null}
                        {validation3.touched.optoutKeywords &&
                        validation3.errors.optoutKeywords ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.optoutKeywords}
                          </Form.Control.Feedback>
                        ) : null}
                        {validation3.touched.optoutMessage &&
                        validation3.errors.optoutMessage ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.optoutMessage}
                          </Form.Control.Feedback>
                        ) : null}
                      </label>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-4">
                          <Radiobox
                            id="subscriberOptoutYes"
                            label="Yes"
                            name="subscriberOptout"
                            value="Yes"
                            checked={
                              validation3.values.subscriberOptout === true
                            }
                            onChange={() =>
                              validation3.setFieldValue(
                                "subscriberOptout",
                                true
                              )
                            }
                          />
                        </div>
                        <div className="me-5">
                          <Radiobox
                            id="subscriberOptoutNo"
                            label="No"
                            name="subscriberOptout"
                            value="No"
                            disabled={campConditions?.reqSubscriberOptout}
                            checked={
                              validation3.values.subscriberOptout === false
                            }
                            onChange={() =>
                              validation3.setFieldValue(
                                "subscriberOptout",
                                false
                              )
                            }
                          />
                        </div>
                        <div
                          className="d-flex align-items-start mb-2"
                          style={{ width: 16, height: 24 }}
                        >
                          {validation3.values.subscriberOptout ? (
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="bottom-end"
                              overlay={
                                <Popover id="popover-positioned-bottom">
                                  <Popover.Body>
                                    <div className="mb-3">
                                      <Form.Label htmlFor="optoutKeywords">
                                        Type Opt-out Keywords here{" "}
                                        <span className="text-primary">*</span>
                                      </Form.Label>
                                      <TagsInput
                                        inputProps={{
                                          placeholder: "Add a keywords",
                                        }}
                                        value={
                                          validation3.values.optoutKeywords
                                            ? validation3.values.optoutKeywords.split(
                                                ","
                                              )
                                            : []
                                        }
                                        onChange={(e: any) => {
                                          validation3.setFieldValue(
                                            "optoutKeywords",
                                            e.toString()
                                          );
                                        }}
                                        addOnBlur={true}
                                        onlyUnique={true}
                                      />
                                      <div>
                                        {validation3.touched?.optoutKeywords &&
                                        validation3.errors?.optoutKeywords ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {validation3.errors?.optoutKeywords}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="mb-3">
                                      <Form.Label htmlFor="optoutMessage">
                                        Type Opt-out Message here{" "}
                                        <span className="text-primary">*</span>
                                      </Form.Label>
                                      <textarea
                                        id="optoutMessage"
                                        name="optoutMessage"
                                        onChange={validation3.handleChange}
                                        onBlur={validation3.handleBlur}
                                        value={
                                          validation3.values.optoutMessage || ""
                                        }
                                        className={`form-control ${
                                          validation3.touched?.optoutMessage &&
                                          validation3.errors?.optoutMessage
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        rows={3}
                                        maxLength={4096}
                                        minLength={40}
                                      />
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          {validation3.touched?.optoutMessage &&
                                          validation3.errors?.optoutMessage ? (
                                            <Form.Control.Feedback
                                              type="invalid"
                                              className="d-block"
                                            >
                                              {
                                                validation3.errors
                                                  ?.optoutMessage
                                              }
                                            </Form.Control.Feedback>
                                          ) : null}
                                        </div>
                                        <div style={{ flex: "none" }}>
                                          {
                                            validation3.values.optoutMessage
                                              ?.length
                                          }{" "}
                                          / 320
                                        </div>
                                      </div>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
                            </OverlayTrigger>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ps-0 pb-1">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 overflow-hidden">
                      <label className="form-check-label mb-0">
                        <span className="d-block fw-semibold text-truncate">
                          Subscriber Help
                        </span>
                        {validation3.touched.subscriberHelp &&
                        validation3.errors.subscriberHelp ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.subscriberHelp}
                          </Form.Control.Feedback>
                        ) : null}
                        {validation3.touched.helpKeywords &&
                        validation3.errors.helpKeywords ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.helpKeywords}
                          </Form.Control.Feedback>
                        ) : null}
                        {validation3.touched.helpMessage &&
                        validation3.errors.helpMessage ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.helpMessage}
                          </Form.Control.Feedback>
                        ) : null}
                      </label>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-4">
                          <Radiobox
                            id="subscriberHelpYes"
                            label="Yes"
                            name="subscriberHelp"
                            value="Yes"
                            checked={validation3.values.subscriberHelp === true}
                            onChange={() =>
                              validation3.setFieldValue("subscriberHelp", true)
                            }
                          />
                        </div>
                        <div className="me-5">
                          <Radiobox
                            id="subscriberHelpNo"
                            label="No"
                            name="subscriberHelp"
                            value="No"
                            disabled={campConditions?.reqSubscriberHelp}
                            checked={
                              validation3.values.subscriberHelp === false
                            }
                            onChange={() =>
                              validation3.setFieldValue("subscriberHelp", false)
                            }
                          />
                        </div>
                        <div
                          className="d-flex align-items-start mb-2"
                          style={{ width: 16, height: 24 }}
                        >
                          {validation3.values.subscriberHelp ? (
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="bottom-end"
                              overlay={
                                <Popover id="popover-positioned-bottom">
                                  <Popover.Body>
                                    <div className="mb-3">
                                      <Form.Label htmlFor="helpKeywords">
                                        Type Help Keywords here{" "}
                                        <span className="text-primary">*</span>
                                      </Form.Label>
                                      <TagsInput
                                        inputProps={{
                                          placeholder: "Add a keywords",
                                        }}
                                        value={
                                          validation3.values.helpKeywords
                                            ? validation3.values.helpKeywords.split(
                                                ","
                                              )
                                            : []
                                        }
                                        onChange={(e: any) => {
                                          validation3.setFieldValue(
                                            "helpKeywords",
                                            e.toString()
                                          );
                                        }}
                                        addOnBlur={true}
                                        onlyUnique={true}
                                      />
                                      <div>
                                        {validation3.touched?.helpKeywords &&
                                        validation3.errors?.helpKeywords ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {validation3.errors?.helpKeywords}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="mb-3">
                                      <Form.Label htmlFor="helpMessage">
                                        Type Help Message here{" "}
                                        <span className="text-primary">*</span>
                                      </Form.Label>
                                      <textarea
                                        id="helpMessage"
                                        name="helpMessage"
                                        onChange={validation3.handleChange}
                                        onBlur={validation3.handleBlur}
                                        value={
                                          validation3.values.helpMessage || ""
                                        }
                                        className={`form-control ${
                                          validation3.touched?.helpMessage &&
                                          validation3.errors?.helpMessage
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        rows={3}
                                        maxLength={4096}
                                        minLength={40}
                                      />
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          {validation3.touched?.helpMessage &&
                                          validation3.errors?.helpMessage ? (
                                            <Form.Control.Feedback
                                              type="invalid"
                                              className="d-block"
                                            >
                                              {validation3.errors?.helpMessage}
                                            </Form.Control.Feedback>
                                          ) : null}
                                        </div>
                                        <div style={{ flex: "none" }}>
                                          {
                                            validation3.values.helpMessage
                                              ?.length
                                          }{" "}
                                          / 320
                                        </div>
                                      </div>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
                            </OverlayTrigger>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ps-0 pb-1">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 overflow-hidden">
                      <label className="form-check-label mb-0">
                        <span className="d-block fw-semibold text-truncate">
                          Number Pooling
                        </span>
                        {validation3.touched.numberPool &&
                        validation3.errors.numberPool ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.numberPool}
                          </Form.Control.Feedback>
                        ) : null}
                      </label>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-4">
                          <Radiobox
                            id="numberPoolYes"
                            label="Yes"
                            name="numberPool"
                            value="Yes"
                            checked={validation3.values.numberPool === true}
                            onChange={() =>
                              validation3.setFieldValue("numberPool", true)
                            }
                          />
                        </div>
                        <div className="me-5">
                          <Radiobox
                            id="numberPoolNo"
                            label="No"
                            name="numberPool"
                            value="No"
                            checked={validation3.values.numberPool === false}
                            onChange={() =>
                              validation3.setFieldValue("numberPool", false)
                            }
                          />
                        </div>
                        <div
                          className="d-flex align-items-start mb-2"
                          style={{ width: 16, height: 24 }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ps-0 pb-1">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 overflow-hidden">
                      <label className="form-check-label mb-0">
                        <span className="d-block fw-semibold text-truncate">
                          Direct Lending or Loan Arrangement
                        </span>
                        {validation3.touched.directLending &&
                        validation3.errors.directLending ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.directLending}
                          </Form.Control.Feedback>
                        ) : null}
                      </label>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-4">
                          <Radiobox
                            id="directLendingYes"
                            label="Yes"
                            name="directLending"
                            value="Yes"
                            checked={validation3.values.directLending === true}
                            onChange={() =>
                              validation3.setFieldValue("directLending", true)
                            }
                          />
                        </div>
                        <div className="me-5">
                          <Radiobox
                            id="directLendingNo"
                            label="No"
                            name="directLending"
                            value="No"
                            checked={validation3.values.directLending === false}
                            onChange={() =>
                              validation3.setFieldValue("directLending", false)
                            }
                          />
                        </div>
                        <div
                          className="d-flex align-items-start mb-2"
                          style={{ width: 16, height: 24 }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
            <Col lg={6}>
              <ul className="list-group list-group-flush">
                <li className="list-group-item ps-0 pb-1">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 overflow-hidden">
                      <label className="form-check-label mb-0">
                        <span className="d-block fw-semibold text-truncate">
                          Embedded Link
                        </span>
                        {validation3.touched.embeddedLink &&
                        validation3.errors.embeddedLink ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.embeddedLink}
                          </Form.Control.Feedback>
                        ) : null}
                      </label>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-4">
                          <Radiobox
                            id="embeddedLinkYes"
                            label="Yes"
                            name="embeddedLink"
                            value="Yes"
                            checked={validation3.values.embeddedLink === true}
                            onChange={() =>
                              validation3.setFieldValue("embeddedLink", true)
                            }
                          />
                        </div>
                        <div className="me-5">
                          <Radiobox
                            id="embeddedLinkNo"
                            label="No"
                            name="embeddedLink"
                            value="No"
                            disabled={campConditions?.noEmbeddedLink}
                            checked={validation3.values.embeddedLink === false}
                            onChange={() =>
                              validation3.setFieldValue("embeddedLink", false)
                            }
                          />
                        </div>
                        <div
                          className="d-flex align-items-start mb-2"
                          style={{ width: 16, height: 24 }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ps-0 pb-1">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 overflow-hidden">
                      <label className="form-check-label mb-0">
                        <span className="d-block fw-semibold text-truncate">
                          Embedded Phone Number
                        </span>
                        {validation3.touched.embeddedPhone &&
                        validation3.errors.embeddedPhone ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.embeddedPhone}
                          </Form.Control.Feedback>
                        ) : null}
                      </label>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-4">
                          <Radiobox
                            id="embeddedPhoneYes"
                            label="Yes"
                            name="embeddedPhone"
                            value="Yes"
                            checked={validation3.values.embeddedPhone === true}
                            onChange={() =>
                              validation3.setFieldValue("embeddedPhone", true)
                            }
                          />
                        </div>
                        <div className="me-5">
                          <Radiobox
                            id="embeddedPhoneNo"
                            label="No"
                            name="embeddedPhone"
                            value="No"
                            disabled={campConditions?.noEmbeddedPhone}
                            checked={validation3.values.embeddedPhone === false}
                            onChange={() =>
                              validation3.setFieldValue("embeddedPhone", false)
                            }
                          />
                        </div>
                        <div
                          className="d-flex align-items-start mb-2"
                          style={{ width: 16, height: 24 }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ps-0 pb-1">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 overflow-hidden">
                      <label className="form-check-label mb-0">
                        <span className="d-block fw-semibold text-truncate">
                          Age-Gated Content
                        </span>
                        {validation3.touched.ageGated &&
                        validation3.errors.ageGated ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.ageGated}
                          </Form.Control.Feedback>
                        ) : null}
                      </label>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-4">
                          <Radiobox
                            id="ageGatedYes"
                            label="Yes"
                            name="ageGated"
                            value="Yes"
                            checked={validation3.values.ageGated === true}
                            onChange={() =>
                              validation3.setFieldValue("ageGated", true)
                            }
                          />
                        </div>
                        <div className="me-5">
                          <Radiobox
                            id="ageGatedNo"
                            label="No"
                            name="ageGated"
                            value="No"
                            checked={validation3.values.ageGated === false}
                            onChange={() =>
                              validation3.setFieldValue("ageGated", false)
                            }
                          />
                        </div>
                        <div
                          className="d-flex align-items-start mb-2"
                          style={{ width: 16, height: 24 }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item ps-0 pb-1">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 overflow-hidden">
                      <label className="form-check-label mb-0">
                        <span className="d-block fw-semibold text-truncate">
                          Terms & Conditions
                        </span>
                        <span className="text-muted d-block">
                          {campaignTnC?.subTerms?.[0]}
                        </span>
                        {validation3.touched.termsAndConditions &&
                        validation3.errors.termsAndConditions ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation3.errors.termsAndConditions}
                          </Form.Control.Feedback>
                        ) : null}
                      </label>
                    </div>
                    <div className="flex-shrink-0 ms-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-4">
                          <Radiobox
                            id="termsAndConditionsYes"
                            label="Yes"
                            name="termsAndConditions"
                            value="Yes"
                            checked={
                              validation3.values.termsAndConditions === true
                            }
                            onChange={() =>
                              validation3.setFieldValue(
                                "termsAndConditions",
                                true
                              )
                            }
                          />
                        </div>
                        <div className="me-5">
                          <Radiobox
                            id="termsAndConditionsNo"
                            label="No"
                            name="termsAndConditions"
                            value="No"
                            disabled={true}
                            checked={
                              validation3.values.termsAndConditions === false
                            }
                            onChange={() =>
                              validation3.setFieldValue(
                                "termsAndConditions",
                                false
                              )
                            }
                          />
                        </div>
                        <div
                          className="d-flex align-items-start mb-2"
                          style={{ width: 16, height: 24 }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
          {/* <Col lg={6}>
            <div className="mb-3">
              <Form.Label htmlFor="resellerId">
                Select if Reseller Involved
              </Form.Label>
              <Select
                name="resellerId"
                styles={styleObj(validation3?.touched?.resellerId && validation3?.errors?.resellerId)}
                theme={customSelectTheme}
                options={
                  Array.isArray(resellersArr) && resellersArr?.length > 0
                    ? resellersArr?.map(
                        (dt: { displayName?: string; brandId?: string }) => ({
                          label: `${dt?.displayName} - ${dt?.brandId}`,
                          value: dt?.brandId,
                        })
                      )
                    : []
                }
                onChange={(e: { value: string }) => {
                  validation3.setFieldValue("resellerId", e.value);
                }}
                value={{
                  label: `${
                    (Array.isArray(resellersArr) &&
                      resellersArr?.find(
                        (dt: { brandId?: string }) =>
                          dt?.brandId === validation3.values.resellerId
                      )?.displayName) ||
                    ""
                  } ${
                    (Array.isArray(resellersArr) &&
                      resellersArr?.find(
                        (dt: { brandId?: string }) =>
                          dt?.brandId === validation3.values.resellerId
                      )?.brandId) ||
                    ""
                  }`,
                  value: validation3.values.resellerId,
                }}
              />
            </div>
            <Button
              onClick={(e) => {
                setResellerModal(true);
              }}
              variant="primary"
            >
              Add New Reseller
            </Button>
          </Col> */}
        </div>
        <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
          <button
            type="button"
            className="btn btn-link text-decoration-none btn-label previestab"
            onClick={() => setactiveTab(1)}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-lg"></i>
            Back to Terms
          </button>
          <button
            type="submit"
            className="btn btn-secondary btn-label right nexttab nexttab"
          >
            <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
            Next
          </button>
        </div>
      </Form>
    </Tab.Pane>
  );
};

export default Step3;
