import React, { useEffect, useState } from "react";
import { Container, Card, Tab, Nav } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getUsageSummaryThunk, getUsageTransactionsThunk } from "slices/thunk";
import Datatable from "../../../Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import { createSelector } from "reselect";
import moment from "moment";
import Tooltip from "rc-tooltip";
import withRouter from "Common/withRouter";
import { useSearchParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import {
  customSelectTheme,
  capitalizeString,
  dateFormat,
  getCurrentDateTime,
  last12Months,
  removeKeyFromObj,
  styleObj,
} from "helpers/common";

const UsageTransactions = (props: any) => {
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const type2 = searchParams.get("type");

  const [summaryObj, setSummaryObj] = useState<any>({});
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("MM-DD-YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("MM-DD-YYYY")
  );
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRangeTemp, setDateRangeTemp] = useState({
    label: moment().startOf("month").format("MMM YYYY"),
    value: moment().startOf("month").format("MM-YYYY"),
  });
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [type, setType] = useState<string | null>("");

  const selectProfile = createSelector(
    (state: any) => state.UsageSummary,
    (usageSummary) => ({
      loading: usageSummary.loading,
      loading2: usageSummary.loading2,
      usageSummaryObj: usageSummary.usageSummaryObj,
      usageTransactionsObj: usageSummary.usageTransactionsObj,
    })
  );

  const { loading, loading2, usageSummaryObj, usageTransactionsObj } =
    useSelector(selectProfile);

  const capitalizeFirstLetter = (str: any) => {
    return str
      .split(" ")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Create Date</span>,
      minWidth: "220px",
      selector: (row: { created: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.created, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
    },
    {
      name: <span className="font-weight-bold fs-sm">Transaction ID</span>,
      selector: (row: { transactionId: string }) => (
        <Tooltip
          placement="top"
          overlay={<p className="mb-0">{row.transactionId}</p>}
        >
          <div>
            {row.transactionId.length > 10
              ? `${row.transactionId.slice(0, 23)}...`
              : row.transactionId}
          </div>
        </Tooltip>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.transactionId.localeCompare(b.transactionId),
    },
    {
      name: <span className="font-weight-bold fs-sm">SID</span>,
      selector: (row: { sid: string }) => (
        <Tooltip placement="top" overlay={<span>{row.sid}</span>}>
          <span className="text-secondary">
            {row.sid.length > 10 ? `${row.sid.slice(0, 23)}...` : row.sid}
          </span>
        </Tooltip>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.sid.localeCompare(b.sid),
    },
    {
      name: <span className="font-weight-bold fs-sm">Service Type</span>,
      minWidth: "180px",
      selector: (cell: { serviceType: string }) => {
        switch (cell.serviceType?.toLowerCase()) {
          case "mt":
            return (
              <span className="badge bg-body-secondary border border-info text-info">
                {cell.serviceType}
              </span>
            );
          case "number.local":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(cell.serviceType.replace("Number.", ""))}
              </span>
            );
          case "number.lookup":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(cell.serviceType.replace("Number.", ""))}
              </span>
            );
          case "number.tollfree":
            return (
              <span className="badge bg-body-secondary border border-info text-info">
                {capitalizeFirstLetter(
                  cell.serviceType
                    .replace("Number.", "")
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()
                )}
              </span>
            );
          case "sms.inbound":
            return (
              <span className="badge bg-body-primary border border-primary text-primary">
                {capitalizeFirstLetter(cell.serviceType.replace("SMS.", ""))}
              </span>
            );

          case "brand.create":
            return (
              <span className="badge bg-body-primary border border-primary text-primary">
                {capitalizeFirstLetter(cell.serviceType.replace("brand.", ""))}
              </span>
            );
          case "mms.inbound":
            return (
              <span className="badge bg-body-primary border border-primary text-primary">
                {capitalizeFirstLetter(cell.serviceType.replace("MMS.", ""))}
              </span>
            );

          case "sms.outbound":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(cell.serviceType.replace("SMS.", ""))}
              </span>
            );

          case "mms.outbound":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(cell.serviceType.replace("MMS.", ""))}
              </span>
            );
          case "campaign.marketing":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(
                  cell.serviceType.toLowerCase().replace("campaign.", "")
                )}
              </span>
            );
          case "campaign.2fa":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(
                  cell.serviceType
                    .toLowerCase()
                    .replace("campaign.", "")
                    .replace(/_/g, " ")
                )}
              </span>
            );
          case "campaign.low_volume":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(
                  cell.serviceType
                    .toLowerCase()
                    .replace("campaign.", "")
                    .replace(/_/g, " ")
                )}
              </span>
            );
          case "brand.vetting_enhanced_aegis":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(
                  cell.serviceType
                    .toLowerCase()
                    .replace("brand.", "")
                    .replace(/_/g, " ")
                )}
              </span>
            );
          case "brand.revet":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(
                  cell.serviceType
                    .toLowerCase()
                    .replace("brand.", "")
                    .replace(/_/g, " ")
                )}
              </span>
            );
          case "brand.vetting_standard_aegis":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(
                  cell.serviceType
                    .toLowerCase()
                    .replace("brand.", "")
                    .replace(/_/g, " ")
                )}
              </span>
            );

          default:
            return (
              <span className="badge bg-danger-subtle text-danger fs-sm">
                {cell.serviceType || "N/A"}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) =>
        (a.serviceType || "")
          .toLowerCase()
          .localeCompare((b.serviceType || "").toLowerCase()),
    },
    {
      name: <span className="font-weight-bold fs-sm">Quantity</span>,
      minWidth: "50px",
      selector: (row: { quantity: number }) => row.quantity,
      sortable: true,
      sortFunction: (a: any, b: any) => (a.quantity || 0) - (b.quantity || 0),
    },
    {
      name: <span className="font-weight-bold fs-sm">Amount</span>,
      selector: (row: { amount: number }) =>
        `$${(row.amount || 0).toFixed(4)} USD`,
      sortable: true,
      sortFunction: (a: any, b: any) => (a.amount || 0) - (b.amount || 0),
    },
  ];

  useEffect(() => {
    type && startDate && endDate && handleGetData(currPage, rowsPerPage);
  }, [type]);

  useEffect(() => {
    if (dateRangeTemp) {
      const formattedStartDate = moment(startDate, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      const formattedEndDate = moment(endDate, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      setType(type2);
      dispatch(getUsageSummaryThunk(formattedStartDate, formattedEndDate));
      handleGetData(currPage, rowsPerPage);
    }
  }, [dateRangeTemp]);

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      setSummaryObj(temp);
      setSearchParams({
        type:
          type2 && Object.keys(temp)?.includes(type2)
            ? type2
            : Object.keys(temp)?.[0],
      });
      setType(
        type2 && Object.keys(temp)?.includes(type2)
          ? type2
          : Object.keys(temp)?.[0]
      );
    } else {
      setSummaryObj({});
      setSearchParams({ type: "" });
      setType("");
    }
  }, [usageSummaryObj]);

  const handleGetData = (page: number, perPage: number) => {
    const formattedStartDate = moment(startDate, "MM-DD-YYYY").format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = moment(endDate, "MM-DD-YYYY").format("YYYY-MM-DD");
    dispatch(
      getUsageTransactionsThunk(
        type || "SMS",
        formattedStartDate,
        formattedEndDate,
        page,
        perPage
      )
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const handleTabChange = (dt: string) => {
    setSearchParams({ type: dt });
    setType(dt);
    setCurrPage(1);
    setRowsPerPage(10);
  };

  const handleSelectChange = (e: any) => {
    if (e?.value !== "Custom") {
      const newStartDate = moment(e.value, "MM-YYYY")
        .startOf("month")
        .format("MM-DD-YYYY");
      const newEndDate = moment(e.value, "MM-YYYY")
        .endOf("month")
        .format("MM-DD-YYYY");
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setDateRangeTemp(e);
      setShowDatePicker(false);
      handleGetData(currPage, rowsPerPage);
    } else {
      setDateRangeTemp({ label: "Custom Range", value: "Custom" });
      setShowDatePicker(true);
    }
  };

  useEffect(() => {
    if (showDatePicker) {
      handleGetData(currPage, rowsPerPage);
    }
  }, [showDatePicker]);

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, [startDate, endDate]);

  return (
    <React.Fragment>
      <div className="page-content position-relative">
        <Container fluid>
          <BreadCrumb
            title="Usage Transactions"
            isFilter={true}
            filterButton={
              <div className="d-flex align-items-center ">
                <p className="mb-0 me-2">As of {getCurrentDateTime()}</p>
                <div style={{ width: "200px" }}>
                  {showDatePicker ? (
                    <Flatpickr
                      className="form-control"
                      name="createdAt"
                      placeholder="Select Range"
                      options={{
                        enableTime: false,
                        altFormat: "F j, Y",
                        dateFormat: "m-d-Y",
                        mode: "range",
                        onChange: (value: any) => {
                          if (value?.[0]) {
                            setStartDate(moment(value[0]).format("MM-DD-YYYY"));
                          }
                          if (value?.[1]) {
                            setEndDate(moment(value[1]).format("MM-DD-YYYY"));
                          }
                          if (value?.[0] && value?.[1]) {
                            setShowDatePicker(false);
                            setDateRangeTemp({
                              label: "Custom Range",
                              value: "Custom",
                            });
                          }
                        },
                      }}
                      value={
                        startDate && endDate ? `${startDate} to ${endDate}` : ""
                      }
                    />
                  ) : (
                    <Select
                      styles={styleObj(false)}
                      theme={customSelectTheme}
                      options={[
                        { label: "Custom Range", value: "Custom" },
                        ...last12Months(),
                      ]}
                      onChange={handleSelectChange}
                      value={dateRangeTemp}
                    />
                  )}
                </div>
              </div>
            }
          />

          {loading ? (
            <div className={`wrapper`}>
              <img
                src={Loader}
                className={`position-absolute top-50 start-50 translate-middle`}
                alt="Loading..."
              />
            </div>
          ) : Object.keys(summaryObj)?.length > 0 ? (
            <Tab.Container defaultActiveKey={type || ""} activeKey={type || ""}>
              <Nav
                as="ul"
                variant="tabs"
                className="nav-tabs-custom nav-primary mb-3"
              >
                {Object.keys(summaryObj).map((dt, i) => (
                  <Nav.Item as="li" onClick={() => handleTabChange(dt)} key={i}>
                    <Nav.Link eventKey={dt}>
                      {dt === "SMS" || dt === "MMS"
                        ? dt.toUpperCase()
                        : capitalizeString(dt)}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                {Object.keys(summaryObj).map((dt, i) => (
                  <Tab.Pane eventKey={dt} key={i}>
                    <div className="position-relative">
                      <Card className="wrapper2">
                        <Card.Body className="listing-table3">
                          {loading2 ? (
                            <div className={``}>
                              <img
                                src={Loader}
                                className={`position-absolute top-50 start-50 translate-middle`}
                                alt="Loading..."
                              />
                            </div>
                          ) : (
                            <Datatable
                              data={
                                type &&
                                Array.isArray(usageTransactionsObj?.records)
                                  ? usageTransactionsObj?.records
                                  : []
                              }
                              columns={columns}
                              handlePageChange={handlePageChange}
                              handleRowsPerPageChange={handleRowsPerPageChange}
                              currPage={currPage}
                              rowsPerPage={rowsPerPage}
                              totalRecords={usageTransactionsObj?.totalRecords}
                            />
                          )}
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          ) : (
            <p className="mt-3">No Record Found</p>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UsageTransactions);
