import React, { useEffect, useState } from "react";
import { Container, Dropdown, Button, Form, Row, Col } from "react-bootstrap";
import Widgets from "./widgets";
import UsageSummary from "./UsageSummary";
import PaymentHistory from "./PaymentHistory";
import LastDays from "./LastDays";
import DeliveryRate from "./DeliveryRate";
import {
  asyncActivePhoneNumberList,
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  asyncTagsList,
  customSelectTheme,
  dateRangeArr,
  getAnalyticCardData,
  getAnalyticDateGraphData,
  getAnalyticNumberGraphData,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import Select from "react-select";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import {
  getAnalyticsMmsV1Thunk,
  getAnalyticsSmsV1Thunk,
  getUsageSummaryThunk,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getLocal, setLocal } from "helpers/services/auth/auth";
import { AsyncPaginate } from "react-select-async-paginate";
import * as Yup from "yup";
import DashboardSkeleton from "./DashboardSkeleton";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";
// import DashboardSkeleton from "./DashboardSkeleton";

const Dashboard = () => {
  document.title = "Signal House Portal Dashboard";
  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (analytics, groups) => ({
      analyticsAllV1: analytics.analyticsAllV1,
      groupDetails: groups?.AllGroups,
    })
  );

  const { analyticsAllV1, groupDetails } = useSelector(selectProfile);

  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [dateRangeTemp, setDateRangeTemp] = useState(dateRangeArr[1]);
  const [cardObj, setCardObj] = useState<any>({});
  const [dateGraphObj, setDateGraphObj] = useState<any>({});
  const [numberGraphObj, setNumberGraphObj] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupId: "",
      subGroupId: [],
      brandId: [],
      campaignId: [],
      phoneNumber: [],
      tag: [],
      createdAt: "",
      startDate: moment().subtract(7, "days").startOf("day").toISOString(),
      endDate: moment().endOf("day").format("MM-DD-YYYY"),
    },
    validationSchema: Yup.object({
      groupId: Yup.string().required("Please Select Group"),
    }),
    onSubmit: (values) => {
      const temp = {
        ...values,
        subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
        brandId:
          values.brandId.length > 0
            ? values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          values.campaignId.length > 0
            ? values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          values.phoneNumber.length > 0
            ? values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag: values.tag.length > 0 ? values.tag?.map((dt: any) => dt.tag) : "",
        startDate: values.startDate
          ? moment(values.startDate).startOf("day").toISOString()
          : "",
        endDate: values.endDate
          ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
      };

      setOpen(!open);
      const formattedStartDate = moment(values.startDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(values.endDate, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      dispatch(getUsageSummaryThunk(formattedStartDate, formattedEndDate));
      handleGetData(temp);

      setLocal("analyticsDateRangeTemp", JSON.stringify(dateRangeTemp));
      setLocal("analyticsStartDate", JSON.stringify(values.startDate));
      setLocal("analyticsEndDate", JSON.stringify(values.endDate));
    },
  });

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      setIsLoading(true);
      const temp = getLocal("analyticsDateRangeTemp");
      temp
        ? setDateRangeTemp(JSON.parse(temp))
        : setDateRangeTemp(dateRangeArr[1]);

      const temp1 = getLocal("analyticsStartDate");
      const temp2 = getLocal("analyticsEndDate");

      const formattedStartDate = temp1
        ? moment(JSON.parse(temp1)).format("YYYY-MM-DD")
        : moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD");
      const formattedEndDate = temp2
        ? moment(JSON.parse(temp2)).format("YYYY-MM-DD")
        : moment().endOf("day").format("YYYY-MM-DD");
      dispatch(getUsageSummaryThunk(formattedStartDate, formattedEndDate));

      const createdAt = `${
        temp1
          ? moment(JSON.parse(temp1)).format("MM-DD-YYYY")
          : moment().subtract(7, "days").startOf("day").format("MM-DD-YYYY")
      } to ${
        temp2
          ? moment(JSON.parse(temp2)).format("MM-DD-YYYY")
          : moment().endOf("day").format("MM-DD-YYYY")
      }`;
      const startDate = temp1
        ? JSON.parse(temp1)
        : moment().subtract(7, "days").startOf("day").toISOString();
      const endDate = temp2
        ? JSON.parse(temp2)
        : moment().endOf("day").format("MM-DD-YYYY");

      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
      validation.setFieldValue("createdAt", createdAt);
      validation.setFieldValue("startDate", startDate);
      validation.setFieldValue("endDate", endDate);

      handleGetData({
        ...validation.values,
        groupId: groupDetails?.records?.[0]?.group_id,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        phoneNumber:
          validation.values.phoneNumber.length > 0
            ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
            : "",
        tag:
          validation.values.tag.length > 0
            ? validation.values.tag?.map((dt: any) => dt.tag)
            : "",
        startDate: startDate
          ? moment(startDate).startOf("day").toISOString()
          : "",
        endDate: endDate
          ? moment(endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
      });

      Promise.all([
        dispatch(getUsageSummaryThunk(formattedStartDate, formattedEndDate)),
        handleGetData({
          ...validation.values,
          groupId: groupDetails?.records?.[0]?.group_id,
          subGroupId:
            validation.values.subGroupId.length > 0
              ? validation.values.subGroupId
              : "",
          brandId:
            validation.values.brandId.length > 0
              ? validation.values.brandId?.map((dt: any) => dt.brandId)
              : "",
          campaignId:
            validation.values.campaignId.length > 0
              ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          phoneNumber:
            validation.values.phoneNumber.length > 0
              ? validation.values.phoneNumber?.map((dt: any) => dt.phoneNumber)
              : "",
          tag:
            validation.values.tag.length > 0
              ? validation.values.tag?.map((dt: any) => dt.tag)
              : "",
          startDate: startDate
            ? moment(startDate).startOf("day").toISOString()
            : "",
          endDate: endDate
            ? moment(endDate, "MM-DD-YYYY").endOf("day").toISOString()
            : "",
        }),
      ]).finally(() => {
        setIsLoading(false);
      });
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  useEffect(() => {
    if (Object.keys(analyticsAllV1)?.length) {
      getAnalyticCardData({
        endDate: validation.values?.endDate,
        startDate: validation.values?.startDate,
        analyticsAllV1,
        setCardObj,
      });
      getAnalyticDateGraphData({
        selectedType: "All",
        analyticsAllV1,
        setDateGraphObj,
      });
      getAnalyticNumberGraphData({
        selectedType: "All",
        analyticsAllV1,
        setNumberGraphObj,
      });
    }
  }, [analyticsAllV1]);

  const handleGetData = (params?: any) => {
    const promises = [];
    promises.push(
      dispatch(
        getAnalyticsSmsV1Thunk(
          removeEmptyAndNullValues({ ...params, createdAt: "" })
        )
      )
    );
    promises.push(
      dispatch(
        getAnalyticsMmsV1Thunk(
          removeEmptyAndNullValues({ ...params, createdAt: "" })
        )
      )
    );
    return Promise.all(promises);
  };

  if (isLoading) {
    return <DashboardSkeleton />;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 me-3">Dashboard</h4>

            <div
              className="d-flex align-items-center justify-content-end"
              style={{ flex: "1 1 0%" }}
            >
              <div className="d-flex align-items-center flex-wrap">
                {validation.values.subGroupId?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Subgroup Id: {validation.values.subGroupId?.join(", ")}
                  </span>
                )}
                {validation.values.brandId?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Brand Id:{" "}
                    {validation.values.brandId
                      ?.map((dt: any) => dt.brandId)
                      ?.join(", ")}
                  </span>
                )}
                {validation.values.campaignId?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Campaign Id:{" "}
                    {validation.values.campaignId
                      ?.map((dt: any) => dt.campaignId)
                      ?.join(", ")}
                  </span>
                )}
                {validation.values.phoneNumber?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Phone Number:{" "}
                    {validation.values.phoneNumber
                      ?.map((dt: any) => dt.phoneNumber)
                      ?.join(", ")}
                  </span>
                )}
                {validation.values.tag?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Tag:
                    {validation.values.tag
                      ?.map((dt: any) => dt.tag)
                      ?.join(", ")}
                  </span>
                )}
                {dateRangeTemp?.value ? (
                  dateRangeTemp?.value === "Custom" ? (
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Date Range: {validation.values.startDate}
                      {validation.values.endDate
                        ? ` to ${validation.values.endDate}`
                        : ""}
                    </span>
                  ) : (
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Date Range: {dateRangeTemp?.value}
                    </span>
                  )
                ) : null}
              </div>
              <Dropdown
                align="end"
                className="ms-2 filterDropDown2"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <div>
                        {validation.touched.groupId &&
                        validation.errors.groupId ? (
                          <p className="text-primary">
                            {validation.errors.groupId}
                          </p>
                        ) : null}
                      </div>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <GroupSelect
                          isDisabled={!validation.values.groupId}
                          key={validation.values.groupId}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId?.length > 0
                              ? validation.values.subGroupId.map(
                                  (dt: string) => ({
                                    sub_group_id: dt,
                                  })
                                )
                              : null
                          }
                          name="subGroupId"
                          loadOptions={asyncSubgroupList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                            }),
                            "subGroupId"
                          )}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) =>
                            option?.sub_group_name +
                            " - " +
                            option?.sub_group_id
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.sub_group_id !==
                                validation.values.subGroupId
                            ) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.map((dt: any) => dt.sub_group_id)
                              );
                              validation.setFieldValue("brandId", []);
                              validation.setFieldValue("campaignId", []);
                              validation.setFieldValue("phoneNumber", []);
                              validation.setFieldValue("tag", []);
                            } else if (!option) {
                              validation.setFieldValue("subGroupId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand</Form.Label>
                        <BrandSelect
                          key={
                            validation.values.subGroupId.toString() ||
                            validation.values.groupId ||
                            "brandId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId?.length > 0
                              ? validation.values.brandId
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                            }),
                            "brandId"
                          )}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) =>
                            option?.displayName + " - " + option?.brandId
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.brandId === validation.values.brandId
                            ) {
                              return;
                            }
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                }))
                              );
                              validation.setFieldValue("campaignId", []);
                              validation.setFieldValue("phoneNumber", []);
                              validation.setFieldValue("tag", []);
                            } else {
                              validation.setFieldValue("brandId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            validation.values.groupId ||
                            "campaignId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId?.length > 0
                              ? validation.values.campaignId
                              : null
                          }
                          loadOptions={asyncCampaignList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "campaignId"
                          )}
                          getOptionValue={(option: any) => option?.campaignId}
                          getOptionLabel={(option: any) => option?.campaignId}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.campaignId === validation.values.campaignId
                            ) {
                              return;
                            }
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt?.campaignId || "",
                                }))
                              );
                              validation.setFieldValue("phoneNumber", []);
                              validation.setFieldValue("tag", []);
                            } else {
                              validation.setFieldValue("campaignId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="phoneNumber">
                          Phone Number
                        </Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.campaignId
                              ?.map((dt: any) => dt.campaignId)
                              .toString() ||
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            validation.values.groupId ||
                            "phoneNumber"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.phoneNumber &&
                              validation?.errors?.phoneNumber
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.phoneNumber
                              ? validation.values.phoneNumber
                              : null
                          }
                          loadOptions={asyncActivePhoneNumberList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                              campaignId:
                                validation.values.campaignId.length > 0
                                  ? validation.values.campaignId?.map(
                                      (dt: any) => dt.campaignId
                                    )
                                  : "",
                            }),
                            "phoneNumber"
                          )}
                          getOptionValue={(option: any) => option?.phoneNumber}
                          getOptionLabel={(option: any) => option?.phoneNumber}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.phoneNumber ===
                                validation.values.phoneNumber
                            ) {
                              return;
                            }

                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.campaignId.find(
                                        (item: any) =>
                                          item.campaignId === dt.campaignId &&
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        campaignId: dt.campaignId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.campaignId]
                                )
                              );
                              validation.setFieldValue(
                                "phoneNumber",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt.campaignId || "",
                                  phoneNumber: dt?.phoneNumber || "",
                                }))
                              );
                              validation.setFieldValue("tag", []);
                            } else {
                              validation.setFieldValue("phoneNumber", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="tag">Tags</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.phoneNumber
                              ?.map((dt: any) => dt.phoneNumber)
                              .toString() ||
                            validation.values.campaignId
                              ?.map((dt: any) => dt.campaignId)
                              .toString() ||
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            validation.values.groupId ||
                            "tag"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.tag && validation?.errors?.tag
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.tag ? validation.values.tag : null
                          }
                          loadOptions={asyncTagsList(
                            removeEmptyAndNullValues({
                              groupId: validation.values.groupId || "",
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                              campaignId:
                                validation.values.campaignId.length > 0
                                  ? validation.values.campaignId?.map(
                                      (dt: any) => dt.campaignId
                                    )
                                  : "",
                              phoneNumber:
                                validation.values.phoneNumber.length > 0
                                  ? validation.values.phoneNumber?.map(
                                      (dt: any) => dt.phoneNumber
                                    )
                                  : "",
                              isReleased: false,
                            }),
                            "tagSearch"
                          )}
                          getOptionValue={(option: any) => option?.tag}
                          getOptionLabel={(option: any) => option?.tag}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.tag === validation.values.tag
                            ) {
                              return;
                            }

                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.campaignId.find(
                                        (item: any) =>
                                          item.campaignId === dt.campaignId &&
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        campaignId: dt.campaignId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.campaignId]
                                )
                              );
                              validation.setFieldValue(
                                "phoneNumber",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.phoneNumber.find(
                                        (item: any) =>
                                          item.phoneNumber === dt.phoneNumber &&
                                          item.campaignId === dt.campaignId &&
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                        campaignId: dt.campaignId || "",
                                        phoneNumber: dt.phoneNumber || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.phoneNumber]
                                )
                              );
                              validation.setFieldValue("tag", option);
                            } else {
                              validation.setFieldValue("tag", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="dateRange">Date Range</Form.Label>
                        <Select
                          styles={styleObj(false)}
                          theme={customSelectTheme}
                          options={dateRangeArr}
                          onChange={(e: any) => {
                            setDateRangeTemp(e);
                            let startDate = "";
                            let endDate = moment()
                              .endOf("day")
                              .format("MM-DD-YYYY");

                            if (e?.value === "24 hours") {
                              startDate = moment()
                                .subtract(1, "days")
                                .startOf("day")
                                .toISOString();
                            } else if (e?.value === "7 days") {
                              startDate = moment()
                                .subtract(7, "days")
                                .startOf("day")
                                .toISOString();
                            } else if (e?.value === "30 days") {
                              startDate = moment()
                                .subtract(30, "days")
                                .startOf("day")
                                .toISOString();
                            } else if (e?.value === "120 days") {
                              startDate = moment()
                                .subtract(120, "days")
                                .startOf("day")
                                .toISOString();
                            } else {
                              endDate = "";
                            }

                            validation.setFieldValue("createdAt", "");
                            validation.setFieldValue("startDate", startDate);
                            validation.setFieldValue("endDate", endDate);
                          }}
                          value={dateRangeTemp}
                        />
                      </Col>
                      {dateRangeTemp?.value === "Custom" ? (
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="createdAt">
                            Select Range
                          </Form.Label>
                          <Flatpickr
                            className="form-control"
                            name="createdAt"
                            placeholder="Select Range"
                            options={{
                              enableTime: false,
                              onOpen: () => {
                                setDatePickerModal(true);
                              },
                              onClose: () => {
                                setDatePickerModal(false);
                              },
                              altFormat: "F j, Y",
                              dateFormat: "m-d-Y",
                              mode: "range",
                              onChange: (
                                value: any,
                                dateStr: string,
                                instance: any
                              ) => {
                                validation.setFieldValue(
                                  "createdAt",
                                  dateStr || ""
                                );
                                value?.[0] &&
                                  validation.setFieldValue(
                                    "startDate",
                                    moment(value?.[0]).format("MM-DD-YYYY")
                                  );
                                value?.[1] &&
                                  validation.setFieldValue(
                                    "endDate",
                                    moment(value?.[1]).format("MM-DD-YYYY")
                                  );
                              },
                            }}
                            value={validation.values.createdAt || ""}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-end">
                        <Button
                          className="btn btn-secondary me-2"
                          type="button"
                          onClick={() => {
                            handleGetData({
                              ...validation.initialValues,
                              groupId: groupDetails?.records?.[0]?.group_id,
                              subGroupId: "",
                              brandId: "",
                              campaignId: "",
                              phoneNumber: "",
                              tag: "",
                              endDate: moment().endOf("day").toISOString(),
                            });
                            validation.resetForm();
                            validation.setFieldValue(
                              "groupId",
                              groupDetails?.records?.[0]?.group_id
                            );
                            setDatePickerModal(false);
                            setDateRangeTemp(dateRangeArr[1]);
                            setLocal(
                              "analyticsDateRangeTemp",
                              JSON.stringify(dateRangeArr[1])
                            );
                            setLocal(
                              "analyticsStartDate",
                              JSON.stringify(
                                moment()
                                  .subtract(7, "days")
                                  .startOf("day")
                                  .toISOString()
                              )
                            );
                            setLocal(
                              "analyticsEndDate",
                              JSON.stringify(
                                moment().endOf("day").format("MM-DD-YYYY")
                              )
                            );
                          }}
                        >
                          Clear
                        </Button>
                        <Button className="btn btn-primary" type="submit">
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Widgets cardObj={cardObj} />
          <Row>
            <UsageSummary cardObj={cardObj} />
            <PaymentHistory />
          </Row>
          <Row>
            <LastDays dateGraphObj={dateGraphObj} />
            <DeliveryRate numberGraphObj={numberGraphObj} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
